@import "styles/utilities/_variables";
$content-height: 50vh;
.pending-bidders-modal {
    .close:focus {
        outline: none;
    }
    &-loading {
        height: $content-height;
    }
    &-content {
        height: $content-height;
        display: grid;
        grid-template-columns: 1fr 1px 270px;
        gap: .5rem;
        [data-simplebar] {
            max-height: $content-height;
            padding-right: 1rem;
        }
        .atg-table {
            margin-top: 0;
            grid-column: 1;
            .bider-row {
                cursor: pointer;
                &.active {
                    background-color: rgba($atg-colour-blue, 0.2);
                }
            }
            .actions {
                width: 55px;
                text-align: right;
                &-button {
                    border: none;
                    background: none;
                    padding: 0 .3rem;
                    outline: none;
                    &.active {
                        color: $atg-colour-blue;
                    }
                }
            }
        }
        .divider {
            grid-column: 2;
            align-self: center;
            height: 80%;
            margin-top: 10%;
            border-right: 1px solid $atg-colour-pale-gray;
        }
        .bidder-panel {
            grid-column: 3;
            position: relative;
            padding-top: 3rem;
            max-height: $content-height;
            &.no-data {
                display: flex;
                justify-content: center;
            }
            [data-simplebar] {
                max-height: calc(#{$content-height} - 3rem);
            }
            .icon-person {
                font-size: 15rem;
                color: $atg-colour-pale-gray;
            }
        }
    }
}