@import 'styles/utilities/_variables';

.lots-aggregations {
  text-align: right;
  line-height: 1.2rem;

  &-title {
    color: $atg-colour-blue;
    font-weight: 600;
  }

  &-value:not(:first-of-type)::before {
    content: ' | ';
  }
}