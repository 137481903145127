.bids-history {
  word-break: break-all;
  overflow: hidden;

  &-content {
    padding: 0 1rem;
  }

  &-item {
    .bid-amount {
      font-weight: 500;
    }

    &:last-of-type {
      margin-bottom: .5rem;

      hr {
        display: none;
      }
    }
  }
}