@import 'styles/bootstrap';
@import 'styles/utilities/_variables';

.auction-details {
  .btn-toggle {
    position: absolute;
    bottom: -1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 3px 7px -5px rgba(77, 73, 94, 0.28);
    text-transform: uppercase;
    border: 1px solid transparent;
    padding: 0.25rem 1rem;
    outline: none;

    small {
      color: #999;
      font-size: 0.6rem;
    }

    [class^="icon-"] {
      color: $atg-colour-blue;
      font-size: 1.2rem;
    }

    @include media-breakpoint-down(sm) {
      bottom: -1.7rem;
    }
  }

  &.open {
    .btn-toggle {
      box-shadow: 0 -3px 7px -5px rgba(77, 73, 94, 0.28);
      position: relative;
      margin-top: -2.5rem;
      padding-top: .1rem;

      @include media-breakpoint-down(sm) {
        margin-top: -2.4rem;
      }
    }

    .divider {
      position: absolute;
      top: 2.4rem;
      width: 100px;
      border-top: 1px solid #ddd;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}