@import 'styles/utilities/_variables';

.bidder-info {
  word-break: break-all;
  overflow: hidden;

  &-content {
    padding: 0 1rem;
  }

  &-summary {
    text-align: center;
  }

  .bidder-details-line {
    display: flex;
    margin-bottom: .7rem;

    [class^="icon-"] {
      margin-right: .5rem;
      font-size: 1.5rem;
      color: $atg_colour_blue;
    }
  }

  .nav-link {
    padding: .5rem;
  }

  dt {
    font-weight: normal;
    font-style: italic;
  }

  dd {
    font-weight: 500;
  }

  .auction-activity {

    &-heading {
      position: relative;
      padding: 1rem 0;
      display: flex;
      justify-content: center;

      hr {
        margin: 0;
        width: 50%;
      }

      &-label {
        position: absolute;
        top: .4rem;
        left: 5.2rem;
        font-size: .8rem;
        text-transform: uppercase;
        background-color: $atg-colour-pale-blue;
        padding: 0 .5rem;
      }
    }
  }
}