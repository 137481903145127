$pagination-border-radius: .3rem;

.pagination {
  li {
    margin-left: -1px;
    background-color: $atg-colour-white;
    border: 1px solid $atg-colour-light-gray;
    cursor: pointer;

    a {
      display: block;
      padding: .5rem 1rem;
    }

    &:first-of-type {
      margin-left: 0;
      border-top-left-radius: $pagination-border-radius;
      border-bottom-left-radius: $pagination-border-radius;
    }

    &:last-of-type {
      border-top-right-radius: $pagination-border-radius;
      border-bottom-right-radius: $pagination-border-radius;
    }

    &:hover {
      background-color: $atg-colour-pale-gray;
    }

    &.selected {
      background-color: $atg-colour-blue;
      color: $atg-colour-white;
      border-color: $atg-colour-blue;
    }

    &.disabled {
      color: $atg-colour-gray;
      cursor: auto;
      pointer-events: none;
    }
  }
}