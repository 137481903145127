.nav-tabs {
  .nav-item {
    cursor: pointer;

    .nav-link {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px solid transparent;

      &.active,
      &:hover {
        border-bottom: 2px solid $atg-colour-blue;
        background: none;
        color: $atg-colour-dark-blue;
      }
    }
  }
}