@import 'styles/utilities/_variables';

.lot-header {
  padding: 0 1rem;
  margin: 0 1rem .5rem;
  color: $atg-colour-gray;

  div {
    white-space: nowrap;
  }

  button {
    border: none;
    background: none;
    outline: none;
    padding-right: 0;
    color: $atg_colour_gray;
    font-size: .9rem;

    .icon-sort_desc {
      margin-left: -.5rem;
    }

    .icon.active {
      color: $atg_colour_blue;
    }
  }
}