@import 'styles/bootstrap';
@import 'styles/utilities/_variables';

$lot-list-header-height: 28px;

.auction-lots {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 1.5rem 0;

  [data-simplebar] {
    max-height: calc(100% - #{$lot-list-header-height} - #{$filters-height});
  }

  .pagination {
    margin: 0;

    li a {
      padding: .4rem .8rem;
    }
  }
}