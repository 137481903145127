@keyframes spinner {
  to {
    transform: rotate(-360deg);
  }
}

.sync-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;

  span {
    font-size: 2rem;
    margin-bottom: .5rem;
    animation: spinner 1.5s linear infinite;
  }
}