.internal-link {
  background: none;
  border: none;
  outline: none;
  text-align: start;
  padding: 0;
  font-weight: inherit;

  &:focus {
    outline: none;
  }
}