@import 'styles/bootstrap';
@import 'styles/utilities/_variables';

.auction-stats {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-top: 1rem;

  @include make-container();
  @include make-container-max-widths();

  .refresh-active-bidders {
    margin-left: 1rem;
    padding: .1rem .5rem;

    @keyframes rotating {
      from {
        transform: rotate(0deg)
      }

      to {
        transform: rotate(360deg)
      }
    }

    .rotating {
      display: inline-block;
      animation: rotating 2s linear infinite;
    }
  }
}