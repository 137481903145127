@import 'styles/utilities/_variables';

.filters-container {
  margin-bottom: .5rem;
  padding-top: .2rem;

  .text-filter {
    width: 40%;

    .input-group-text {
      background-color: $atg_colour_white;
    }

    .dropdown-toggle {
      color: $atg_colour_dark_gray;
      background-color: $atg_colour_white;
      border-color: #ced4da;

      &:focus {
        box-shadow: none;
      }
    }

    .dropdown-menu {
      margin-top: 0;
    }
  }

  .reserve-filter {
    display: flex;
    align-items: center;
    margin-right: 2rem;

    &-button {
      background-color: $atg_colour_white;
      color: $atg_colour_dark_gray;
      border-color: #ced4da;

      &.btn.active,
      &.btn:focus {
        background-color: $atg_colour_blue;
        color: $atg_colour_white;
        border-color: $atg_colour_blue;
      }
    }
  }

  .closed-lots-toggle-container {
    display: flex;
    align-items: center;

    .react-toggle {
      margin-left: .5rem;

      .react-toggle-track {
        background-color: $atg_colour_gray;
      }

      &:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: $atg_colour_semi_dark_gray;
      }

      &--checked .react-toggle-track {
        background-color: $atg_colour_blue;
      }

      &--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: $atg_colour_dark_blue;
      }

      &--checked .react-toggle-thumb {
        border-color: $atg_colour_blue;
      }
    }
  }

  .reset-filters-button {
    border: none;
    background-color: $atg_colour_white;
    padding: 0;
    color: $atg_colour_dark_blue;
  }
}