@import 'styles/utilities/_variables';

.reserve-breakdown {
  display: flex;

  &-item {
    flex: 1 0 33.33%;
    word-break: break-all;

    .reserve-price {
      color: $atg-colour-semi-dark-gray;
    }
  }
}