html,
body,
#root {
  width: 100%;
  height: 100vh;
  font-size: 14px;
}

small {
  font-size: .9rem;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.3);
}

.text-underline {
  text-decoration: underline;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
}

.text-tight {
  line-height: 1.15rem;
}

.font-weight-semi-bold {
  font-weight: 500;
}

.form-control {
  color: $atg_colour_dark_gray;

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: #ced4da;
  }
}