// colours
$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);
$pale-gray: rgb(233, 233, 233);
$light-gray: rgb(200, 200, 200);
$gray: rgb(139, 146, 147);
$semi-dark-gray: rgb(77, 73, 94);
$dark-gray: rgba(48, 49, 51, 1);
$pale-blue: rgba(250, 253, 255, 1);
$light-blue: rgba(238, 250, 255, 1);
$blue: rgb(78, 147, 226);
$dark-blue: rgb(47, 112, 185);
$pale-orange: rgb(255, 242, 229);
$orange: rgb(255, 161, 0);
$orangered: orangered;

$atg-colour-primary: $light-blue;
$atg-colour-pale-blue: $pale-blue;
$atg-colour-blue: $blue;
$atg-colour-dark-blue: $dark-blue;
$atg-colour-pale-orange: $pale-orange;
$atg-colour-orange: $orange;
$atg-colour-orangered: $orangered;
$atg-colour-white: $white;
$atg-colour-black: $black;
$atg-colour-pale-gray: $pale-gray;
$atg-colour-light-gray: $light-gray;
$atg-colour-gray: $gray;
$atg-colour-semi-dark-gray: $semi-dark-gray;
$atg-colour-dark-gray: $dark-gray;

$box-shadow-colour-primary: rgba(77, 73, 94, 0.28);
$box-shadow-colour-secondary: rgba(77, 73, 94, 0.1);
$box-shadow-colour-light: rgba(233, 233, 233, 0.05);

//paths
$font-path: '~assets/fonts/';
$img-path: '~assets/images/';

// heights
$header-height: 50.75px;
$auction-details-height: 55px+28px;
$auction-details-height-mobile: 100px+28px;
$filters-height: 68.5px;
$pagination-height: 86px;