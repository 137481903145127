@import 'styles/bootstrap';
@import 'styles/utilities/_variables';

$pagination-height: 72px;

.side-panel {
  background-color: $atg-colour-pale-blue;
  border-bottom-left-radius: 3.5rem;
  padding: .5rem 0 2rem;
  overflow: hidden;
  position: relative;
  max-height: calc(100% - #{$pagination-height});

  [data-simplebar] {
    max-height: calc(100% - 2rem);
  }

  &-heading {
    position: absolute;
    top: 1.1rem;
    left: 1rem;
    right: 1rem;
    text-align: center;
    font-size: .8rem;
    pointer-events: none;
  }

  &-content {
    padding-top: 3rem;
  }

  .close-button {
    background: none;
    border: none;
    outline: none;
    float: right;
    font-size: 1.5rem;
    color: $atg_colour_blue;
  }
}