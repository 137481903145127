@import 'styles/utilities/_variables';

.edit-lot {
  overflow: hidden;

  &-content {
    padding: 0 1rem;

    input:focus {
      outline: none;
    }

    &-heading {
      position: relative;
      padding: 1rem 0;
      display: flex;
      justify-content: center;
      margin: 1rem 0 .5rem;

      hr {
        margin: 0;
        width: 50%;
      }

      &-label {
        position: absolute;
        top: .4rem;
        font-size: .8rem;
        text-transform: uppercase;
        background-color: $atg-colour-pale-blue;
        padding: 0 .5rem;
      }
    }
  }

  &-increments {
    white-space: nowrap;
  }
}