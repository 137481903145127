@import 'styles/bootstrap';
@import 'styles/utilities/_variables';

.auction-details-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    @include make-container();
    @include make-container-max-widths();
  }

  @include media-breakpoint-down(sm) {
    display: block;
    text-align: center;
  }

  .auction-dates strong {
    font-weight: 600;
  }

  &-indicators {
    display: flex;
    align-items: center;

    .pending-bidders {
      display: inline-flex;
      align-items: center;
      gap: .2rem;
      color: $atg-colour-orangered;
      font-weight: 600;
      font-size: .9rem;
      margin-right: .5rem;

      .icon-approve {
        color: $atg-colour-orangered;
        font-size: 1.2rem;
      }
    }
  }
}