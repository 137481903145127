@import 'styles/bootstrap';
@import 'styles/utilities/_variables';

.auction-details {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 10px 10px -10px $box-shadow-colour-secondary;
  background-color: white;
  z-index: 9;

  @include media-breakpoint-down(sm) {
    box-shadow: 0 3px 5px $box-shadow-colour-secondary;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    @include make-container();
    @include make-container-max-widths();
  }
}