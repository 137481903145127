$icomoon-font-family: 'timed-dashboard' !default;
$icomoon-font-path: '#{$font-path}icons' !default;
$icomoon-font-hash: 'vkd60l' !default;

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?#{$icomoon-font-hash}');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?#{$icomoon-font-hash}#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?#{$icomoon-font-hash}') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?#{$icomoon-font-hash}') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?#{$icomoon-font-hash}#timed-dashboard') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'timed-dashboard' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.icon-sort_asc:before {
  content: "\e908";
}

.icon-sort_desc:before {
  content: "\e909";
}

.icon-sync:before {
  content: "\e900";
}

.icon-location:before {
  content: "\e901";
}

.icon-mail:before {
  content: "\e902";
}

.icon-time:before {
  content: "\e903";
}

.icon-phone:before {
  content: "\e904";
}

.icon-search:before {
  content: "\e905";
}

.icon-calendar:before {
  content: "\e906";
}

.icon-double_arrow_down:before {
  content: "\e907";
  display: inline-block;
  transform: rotate(90deg);
}

.icon-double_arrow_up:before {
  content: "\e907";
  display: inline-block;
  transform: rotate(-90deg);
}

.icon-clearclose:before {
  content: "\e90a";
}

.icon-check-circle:before {
  content: "\e90b";
  color: green;
}

.icon-arrow-circle-o-down:before {
  content: "\e90c";
  color: red;
}

.icon-edit:before {
  content: "\e90d";
}

.icon-info:before {
  content: "\e90e";
}

.icon-warning:before {
  content: "\ea08";
}

.icon-approve:before {
  content: "\e912";
}

.icon-portrait:before {
  content: "\e913";
}

.icon-eye:before {
  content: "\e90f";
}

.icon-check:before {
  content: "\e910";
}

.icon-person:before {
  content: "\e911";
}