@import 'styles/utilities/_variables';

.atg-header {
  background: $atg-colour-primary;
  position: relative;
  z-index: 999;
  height: $header-height;
  overflow: hidden;

  .atg-navbar {
    font-size: 0.95rem;

    img {
      max-height: 2rem;
    }

    a.navbar-brand {
      white-space: normal;
      text-align: center;
      word-break: break-all;
    }

    .navbar-brand,
    .navbar-brand:hover,
    .navbar-brand:focus {
      font-size: 1rem;
      font-weight: 400;
      color: $atg-colour-gray;
    }

    .navbar-text {
      color: $atg-colour-dark-gray;
      font-weight: 400;
      white-space: nowrap;
    }

    &.box-shadow {
      box-shadow: 0 0.25rem 0.75rem $box-shadow-colour-light;
    }

    .atg-nav {
      margin-right: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .auction-link {
        color: inherit;
        text-decoration: none;
      }

      .auction-ref {
        color: $atg-colour-gray;
      }
    }

  }
}