@import 'styles/utilities/_variables';

.lot-row {
  font-weight: 600;
  box-shadow: 0 1px 8px $box-shadow-colour-secondary;
  border-radius: 1rem;
  margin: 1rem 1rem;
  padding: 1rem;
  overflow: hidden;
  height: 125px;

  [class*="col"] {
    padding-right: 0;
  }

  a {
    color: inherit;
  }

  .lot-image {
    position: absolute;
    top: -1rem;
    left: -1rem;
    border-top-left-radius: 1.1rem;
  }

  .overlay {
    position: absolute;
    top: -1rem;
    left: -1rem;
    font-size: .8rem;
    background-color: $atg-colour-white;
    box-shadow: 0 1px 8px $box-shadow-colour-secondary;
    border-bottom-right-radius: .5rem;
    padding: .25rem .5rem .25rem .75rem;
    z-index: 10;
  }

  .icon-warning {
    color: darkorange;
  }

  .change-label {
    color: red;
    text-transform: uppercase;
    font-size: .8rem;
    line-height: 1rem;
  }

  .reserve {
    display: flex;
    align-items: center;
    line-height: 1.8rem;

    &-met {
      color: green;
    }

    &-not-met {
      color: red;
    }
  }

  .edit-lot {
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    color: $atg-colour-gray;

    :hover {
      color: $atg-colour_blue;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}